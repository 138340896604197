//
// Custom styling for my theme
// --------------------------------------------------

// Break Points

@screen-sm-min: 768px;
@screen-md-min: 992px;
@screen-lg-min: 1200px;

// Colours
@sky: #74B0BB;
@mustard: #D2A71C;
@teal: #0B6578;
@blush: #C65858;
@grass: #767D2F;
@peach: #FC7A70;


@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,400;0,700;1,400&display=swap');
//Fonts
@bodyfont: 'Fira Sans', sans-serif;

html {
	scroll-behavior: smooth;
}

// Inline and block code styles
body {
	background-color: @sky;
	font-family: @bodyfont;
	font-size: 1.6em;
	color: white;
	-webkit-font-smoothing: antialiased;

	/* Small devices (tablets, 768px and up) */
    @media (min-width: @screen-sm-min) {
    	font-size: 1.8em;
    }
	/* Medium devices (desktops, 992px and up) */
	@media (min-width: @screen-md-min) {
		font-size: 1.75em;
	}
	/* Large devices (desktops, 1200px and up) */
	@media (min-width: @screen-lg-min) {
		font-size: 2.2em;
	}
}

h1 {
	font-size: 2.8em;
	font-weight: bold;
	line-height: 1;
	letter-spacing: -1px;
	color: #ffffff;
	margin-bottom: 50px;

	/* Small devices (tablets, 768px and up) */
    @media (min-width: @screen-sm-min) {
    	font-size: 3em;
    }
	/* Medium devices (desktops, 992px and up) */
	@media (min-width: @screen-md-min) {
		font-size: 3em;
	}
	/* Large devices (desktops, 1200px and up) */
	@media (min-width: @screen-lg-min) {
		font-size: 3em;
	}

	&.subtitle {
		font-size: 1.6em;
		font-weight: 400;
		line-height: 120%;
		letter-spacing: -1px;
		color: #ffffff;

		/* Small devices (tablets, 768px and up) */
	    @media (min-width: @screen-sm-min) {
	    	font-size: 1.6em;
	    }
		/* Medium devices (desktops, 992px and up) */
		@media (min-width: @screen-md-min) {
			font-size: 1.6em;
		}
		/* Large devices (desktops, 1200px and up) */
		@media (min-width: @screen-lg-min) {
			font-size: 1.6em;
		}

		.strong {
			font-weight: 700;
		}
	}
}

h2 {
	font-size: 1.6em;
	font-weight: bold;
	line-height: 1;
	letter-spacing: -1px;
	color: #ffffff;
	margin: 0 0 20px 0;

	/* Small devices (tablets, 768px and up) */
    @media (min-width: @screen-sm-min) {
    	font-size: 1.6em;
    }
	/* Medium devices (desktops, 992px and up) */
	@media (min-width: @screen-md-min) {
		font-size: 1.6em;
	}
	/* Large devices (desktops, 1200px and up) */
	@media (min-width: @screen-lg-min) {
		font-size: 1.6em;
		margin: 0 0 30px 0;
	}
}

.section-icon {
	font-size: 0.8em;
  margin-right: 10px;
}

p {
	line-height: 120%;
	margin-bottom: 30px;

	.italic {
		font-style: italic;
	}
}

a {
	color: white;
	text-decoration: none;
	border-bottom: solid 1px white;
	transition: all ease-in-out 0.2s;
	transition: 0.1s linear;

	&:active {
		color: white;
		text-decoration: none;
	}
	&:focus {
		color: white;
		text-decoration: none;
	}

	&:hover {
		color: darken(white, 10%);
		text-decoration: none;
		border-bottom: solid 2px white;
		transition: 0.1s linear;
	}

	.glyphicon {
		font-size: 0.75em;
		font-weight: 300;
	}
}

.navbar, .navbar-default {
	background-color: transparent;
	border: 0;
}

.navbar-default .navbar-nav>li>a {
	font-size: 2em;
	font-weight: bold;
	color: white;
	-webkit-font-smoothing: antialiased;

	/* Small devices (tablets, 768px and up) */
    @media (min-width: @screen-sm-min) {
    	font-size: 1em;
    }
	/* Medium devices (desktops, 992px and up) */
	@media (min-width: @screen-md-min) {
		font-size: 1em;
	}
	/* Large devices (desktops, 1200px and up) */
	@media (min-width: @screen-lg-min) {
		font-size: 1em;
	}
}

.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
	color: white;
	background-color: @grass;
	border-bottom: 0;

	&.nav-process {
		background-color: @sky;
	}
	&.nav-recommendations {
		background-color: @blush;
	}
	&.nav-work {
		background-color: @teal;
	}
	&.nav-contact {
		background-color: @mustard;
	}
}

.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
	color: white;
	border-bottom: solid 1px white;
}

.navbar-toggle {
	border: 0;

	&:focus, &:hover {
		background-color: transparent !important;
	}

	.icon-bar {
	    width: 34px;
	    height: 4px;
	    border-radius: 0;
	}
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: white;
}

.navbar-collapse {
	background-color: @blush;

	/* Small devices (tablets, 768px and up) */
    @media (min-width: @screen-sm-min) {
    	background-color: transparent;
    }

    .nav, .navbar-default {
    	float: left;
    	width: 100%;
    	margin: 0;

    	/* Small devices (tablets, 768px and up) */
	    @media (min-width: @screen-sm-min) {
	    	float: right;
    		width: auto;
	    }

	    li {

	    	a {
	    		text-align: center;
	    		padding: 30px 0;
	    		border-bottom: 0;

	    		/* Small devices (tablets, 768px and up) */
	    		@media (min-width: @screen-sm-min) {
	    			text-align: left;
	    			padding: 75px 15px 10px;
	    		}
	    	}
	    }
    }
}

.header {
	position: fixed;
	top: 0;
	z-index: 100;
	width: 100%;
	height: 50px;
	background-image: url('../img/header_profilePic.png');
	background-size: 40px auto;
	background-position: 10px 10px;
	background-repeat: no-repeat;

	/* Small devices (tablets, 768px and up) */
    @media (min-width: @screen-sm-min) {
    	height: 100px;
    	background-size: 80px auto;
    }
	/* Medium devices (desktops, 992px and up) */
	@media (min-width: @screen-md-min) {
		height: 100px;
		background-size: 80px auto;
	}
	/* Large devices (desktops, 1200px and up) */
	@media (min-width: @screen-lg-min) {
		height: 100px;
		background-size: 80px auto;
	}
}

.arrow-btn-wrapper {
	position: absolute;
	bottom: 0;
	width: 180px;
	height: 52px;
	left: 50%;
  margin-left: -90px;
	margin-bottom: -30px;
	background-size: 180px auto;
	background-position: center center;
	text-indent: -9999px;

	&-one {
		background-image: url('../img/arrow_down_1@2x.png');
		background-repeat: no-repeat;
	}
	&-two {
		background-image: url('../img/arrow_down_2@2x.png');
		background-repeat: no-repeat;
	}

	/* Small devices (tablets, 768px and up) */
    @media (min-width: @screen-sm-min) {
    width: 200px;
		height: 62px;
		margin-left: -100px;
		margin-bottom: -35px;
		background-size: 200px auto;
    }
	/* Medium devices (desktops, 992px and up) */
	@media (min-width: @screen-md-min) {
		width: 300px;
		height: 88px;
		margin-left: -150px;
		margin-bottom: -50px;
		background-size: 300px auto;
	}
	/* Large devices (desktops, 1200px and up) */
	@media (min-width: @screen-lg-min) {
		width: 360px;
		height: 104px;
		margin-left: -180px;
		background-size: 360px auto;
	}

	a {
	    display: block;
    	height: 100%;
    	border-bottom: 0;

    	&:hover {
    		border-bottom: 0;
    	}
	}
}

.block {
	position: relative;
	padding-top: 150px;
	padding-bottom: 150px;

	/* Small devices (tablets, 768px and up) */
    @media (min-width: @screen-sm-min) {
    	padding-top: 200px;
		padding-bottom: 150px;
    }
	/* Medium devices (desktops, 992px and up) */
	@media (min-width: @screen-md-min) {

	}
	/* Large devices (desktops, 1200px and up) */
	@media (min-width: @screen-lg-min) {
		padding-top: 200px;
		padding-bottom: 150px;
	}
}

.block-intro {
	z-index: 17;
	background-color: @sky;

	.cta-wrapper {
		position: absolute;
	    bottom: 0;
	    width: 100%;

	    /* Small devices (tablets, 768px and up) */
	    @media (min-width: @screen-sm-min) {
	    	position: absolute;
	    	bottom: 0;
	    }

	    .cta-links {
	    	list-style-type: none;
	    	width: 100%;
	    	margin: 0;
	    	padding: 0;

	    	li {
	    		display: block;
	    		width: 100%;
	    		list-style-type: none;
				  transition: background-color .25s ease-in-out;

	    		/* Small devices (tablets, 768px and up) */
			    @media (min-width: @screen-sm-min) {
			    	display: inline-block;
			    }

	    		&.link-about {
	    			background-color: @mustard;

	    			&:hover {
	    				background-color: darken(@mustard, 5%);
	    			}

	    			/* Small devices (tablets, 768px and up) */
				    @media (min-width: @screen-sm-min) {
				    	width: 33%;
				    }
				    /* Medium devices (desktops, 992px and up) */
						@media (min-width: @screen-md-min) {
							width: 40%;
						}
	    		}
	    		&.link-process {
	    			background-color: @teal;

	    			&:hover {
	    				background-color: darken(@teal, 5%);
	    			}

	    			/* Small devices (tablets, 768px and up) */
				    @media (min-width: @screen-sm-min) {
				    	width: 33%;
				    }
				    /* Medium devices (desktops, 992px and up) */
						@media (min-width: @screen-md-min) {
							width: 40%;
						}
	    		}
	    		&.link-contact {
	    			background-color: @blush;

	    			&:hover {
	    				background-color: darken(@blush, 5%);
	    			}

	    			/* Small devices (tablets, 768px and up) */
				    @media (min-width: @screen-sm-min) {
				    	width: 33%;
				    }
				    /* Medium devices (desktops, 992px and up) */
						@media (min-width: @screen-md-min) {
							width: 20%;
						}
	    		}

	    		a {
	    			display: block;
	    			padding: 5px;
	    			color: white;
	    			text-decoration: none;
	    			border-bottom: 0;

	    			&:hover {
	    				text-decoration: none;
	    				border-bottom: 0;
	    			}

	    			/* Small devices (tablets, 768px and up) */
				    @media (min-width: @screen-sm-min) {
				    	padding: 20px 0 100px 20px;
				    }
				    /* Medium devices (desktops, 992px and up) */
					@media (min-width: @screen-md-min) {
						padding: 20px 0 100px 20px;
					}

					h2 {
						margin: 0;
						padding: 0;
					}
	    	}
	    }
	  }
	}
}

.block-about {
	z-index: 16;
	background-color: @mustard;
}

.block-process {
	z-index: 15;
	background-color: @teal;
}

.block-tool {
	z-index: 14;
	background-color: @blush;
}

.block-recommendations {
	z-index: 13;
	background-color: @grass;
}

.block-work {
	z-index: 12;
	background-color: @peach;
}

.block-case-studies {
	z-index: 11;
	background-color: @teal;
}

.block-main-footer {
	z-index: 10;
	background-color: darken(@sky, 40%);
	padding: 100px 0;
}

.block-post-footer {
	z-index: 9;
	background-color: darken(@sky, 50%);
}

blockquote {
	width: 90%;
	background-image: url('../img/icon_quote_open@2x.png');
	background-size: 30px auto;
	background-repeat: no-repeat;
	background-position: top left;
	margin-bottom: 100px;
	padding: 25px 0 0 20px;
	border: 0;

	/* Medium devices (desktops, 992px and up) */
	@media (min-width: @screen-md-min) {
		width: 80%;
		background-size: 50px auto;
		padding: 35px 0 0 50px;
	}

	p {
		background-image: url('../img/icon_quote_close@2x.png');
		background-size: 30px auto;
		background-repeat: no-repeat;
		background-position: bottom right;
		padding: 0 30px 22px 0;
		font-size: 1em;
		font-weight: normal;

		/* Small devices (tablets, 768px and up) */
	    @media (min-width: @screen-sm-min) {
	    	font-size: 1.1em;
	    }
		/* Medium devices (desktops, 992px and up) */
		@media (min-width: @screen-md-min) {
			font-size: 1.3em;
			background-size: 50px auto;
			padding: 0 35px 35px 0;
		}
		/* Large devices (desktops, 1200px and up) */
		@media (min-width: @screen-lg-min) {
			font-size: 1.4em;
		}
	}

	cite {
		float: right;
		color: #9fa370;
		line-height: 100%;
	}
}

.listed-paras {
	margin-bottom: 50px;

	li {
		list-style-type: none;
		margin-bottom: 20px;
	}
}

.btn-primary {
	border: 0;
  border-radius: 0px;
  padding: 10px 30px;
  background-color: @teal;
  transition: all ease-in-out 0.2s;

  &:active, &:focus {
  	background-color: @teal;
  }

  &:hover {
  	background-color: @blush;
  }

  &:focus {
  	outline:0;
  }
}

ul.logos-list {
	magin: 0;
	padding: 0;

	li {
		list-style-type: none;
		magin: 0;
		padding: 0;

		img {
			display: block;
    	//margin: 0 auto;
			max-width: 50%;
		}
	}
}

// Bootstrap accordion styling
.panel-group {
	margin-bottom: 4em;

	&.process-accordion {

		.panel {
			background-color: darken(@peach, 5%);

			&-heading {
				background-color: darken(@peach, 5%);
			}
		}
	}

	&.case-studies-accordion {

		.panel {
			background-color: darken(@teal, 5%);

			&-heading {
				background-color: darken(@teal, 5%);
			}
		}
	}

	.panel {
		border-radius: 0;
		border: 0;

		.panel-heading {
			border: 0;
			border-radius: 0;
			border: 0;

			h4.panel-title {
				font-size: 1.2em;
		    font-weight: 700;
		    color: white;

				a {
					display: block;
					text-decoration: none;
					border-bottom: 0;
					color: white;

					&:hover, &active {
						opacity: 0.5;
					}

					&.collapsed {
						.glyphicon {
							transform: rotate(-90deg);
							transition: 0.1s linear;
						}
					}

					.glyphicon {
						transform: rotate(0deg);
						transition: 0.1s linear;
					}
				}
			}
		}

		.panel-body {
			border-top: 0 !important;

			h4 {
				font-weight: 600;
				opacity: 0.5;
			}
		}
	}
}


footer {
	font-size: 0.9em;

	/* Medium devices (desktops, 992px and up) */
	@media (min-width: @screen-md-min) {
		font-size: 0.8em;
	}
	/* Large devices (desktops, 1200px and up) */
	@media (min-width: @screen-lg-min) {
		font-size: 0.7em;
	}

	.footer-block {
		border-right: 0;

		/* Medium devices (desktops, 992px and up) */
		@media (min-width: @screen-md-min) {
			border-right: solid 0.5px white;
		}

		&:last-child {
			border-right: 0;
		}
	}

	.icon-download {
		border-bottom: solid 1px white;
		padding: 0 20px 0 0;
		background-image: url('../img/icon_download_arrow@2x.png');
		background-size: 15px auto;
		background-repeat: no-repeat;
		background-position: center right;

		&:hover {
			background-position: bottom right;
		}
	}

	.contact-list {
		margin: 0;
		padding: 0;

		.glyphicon {
			display: inline-block;
			margin-left: 10px;
		}

		li {
			list-style-type: none;
			padding: 0;
			margin: 0 0 20px 0;

			.contact-list {

				&-label {
					display: block;
					padding: 0;
					margin: 0;
					text-align: left;
				}
				&-detail {
					display: block;
					padding: 0;
					margin: 0;
					text-align: left;
				}
			}
		}
	}

	.availability {

		&-yes {
			color: @grass;
			font-weight: 600;
			text-transform: uppercase;
		}
	}
}

#footer-support {
	font-size: 0.7em;
	padding: 0.5em 0;
}


/*
 * Bootstrap Photo Gallery
 * https://github.com/michaelsoriano/bootstrap-photo-gallery
 *
*/

.bsPhotoGallery {
	margin: 0;
	padding: 0;

	li {
		list-style-type: none;
		margin-bottom: 30px;

		&.row-divider {
			width: 100%;
				margin-top: 50px;
				display: block;
				background-color: transparent;
				float: left;
		}

		img {
			max-width: 100%;
		}
	}
}

.modal-backdrop {

	&.in {
		opacity: 0.9;
	}
}

.bsPhotoGalleryModal {

	.modal-content {
		background-color: black;
		border-radius:0;
	}
}

.bsPhotoGalleryModal .modal-dialog img {
    text-align:center;
    margin:0 auto;
    width:100%;
}
.bsPhotoGalleryModal .modal-body {
    padding:0px !important;
}
.bsPhotoGalleryModal .bsp-close {
  position: absolute;
  right: -14px;
  top: -11px;
  font-size: 30px;
  color:#fff;
  text-shadow: 1px 1px 18px #000;
}

.bsPhotoGalleryModal .bsp-close:hover {
  cursor: pointer;
  opacity:.6;
  text-shadow: none;

}
.bspHasModal {
  cursor: pointer;

	.imgWrapper {
		overflow: hidden;
	  max-height: 200px;
		border: solid 3px darken(@peach, 10%);

		&:hover {
			border-color: darken(@peach, 30%);
			transition: 0.2s linear;
		}

		/* Medium devices (desktops, 992px and up) */
		@media (min-width: @screen-md-min) {
			border-width: 6px;
		}
		/* Large devices (desktops, 1200px and up) */
		@media (min-width: @screen-lg-min) {
			border-width: 8px;
		}
	}

	p.text {
		display: none;
		overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
		font-size: 0.7em;
	}
}

.bsp-text-container {
  clear:both;
  display:block;
  padding-bottom: 5px;

	h6{
	  margin-bottom: 0;
		font-size: 1em;
	  font-weight: bold;
	  color: white;
	  padding-left: 12px;
	  padding-right: 12px;
	  margin-bottom: 5px;
	}

	.pText {
	  font-size: 0.5em;
		color: white;
	  margin-bottom: 0px;
	  padding: 0 12px 5px;
	}
}

a.bsp-controls,
a.bsp-controls:visited,
a.bsp-controls:active {
  position: absolute;
  top: 44%;
  font-size: 26px;
  color: #fff;
  text-shadow: 1px 1px 18px #000;
}
a.bsp-controls.next {
  right:-10px;
}
a.bsp-controls.previous {
  left:-10px;
}
a.bsp-controls:hover {
  opacity:.6;
  text-shadow: none;
}

@media screen and (max-width: 380px){
   .col-xxs-12 {
     width:100%;
   }
   .col-xxs-12 img {
     width:100%;
   }
}
